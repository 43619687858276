import { LOAD_STATUS_PENDING, LOAD_STATUS_STALE } from 'state-domains/constants';
import { IObject } from 'state-domains/store';
import { pendingReducer } from 'state-domains/utils';

import { AsyncState } from '../../types';
import { Subscription } from '../subscription';
import { MXDTimestamp } from '../utils';

export const INITIAL_USER = {
    id: '',
    email: '',
    profile: {
        name: '',
        initials: '',
        color: '',
    },
    appcueID: '',
    createdAt: { date: 0 },
    xid: '',
    uid: '',
};

export const INITIAL_LOADING_STATE = {
    status: LOAD_STATUS_STALE,
    error: null,
};

export const INITIAL_SELECTED_STATE: CurrentSubscription = {
    status: LOAD_STATUS_PENDING,
    error: null,
    id: '',
};

export const INITIAL_USER_ACCOUNTS_STATE: UserAccountState & AsyncState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    accounts: [],
    singleSubscription: {},
    user: {},
    id: '',
};
export const INITIAL_USER_STATE: UserState = {
    ...INITIAL_USER,
    status: LOAD_STATUS_PENDING,
    error: null,
    recentProjects: [],
    isUserModalOpen: false,
    loadRecentProjectsState: INITIAL_LOADING_STATE,
    selected: INITIAL_SELECTED_STATE,
    subscriptions: [],
    accountPreferences: pendingReducer({}) as AccountPreference & AsyncState,
    defaultSubscriptionState: INITIAL_LOADING_STATE,
    userAccounts: INITIAL_USER_ACCOUNTS_STATE,
};

export interface UserState extends User, AsyncState {
    recentProjects: RecentProject[];
    isUserModalOpen: boolean;
    loadRecentProjectsState: AsyncState;
    selected: CurrentSubscription;
    subscriptions: Subscription[];
    accountPreferences: AccountPreference & AsyncState;
    defaultSubscriptionState: AsyncState;
    userAccounts: AsyncState & UserAccountState;
}

export interface UserAccountState {
    accounts: Subscription[];
    singleSubscription: Record<string, any>;
    user: Record<string, any>;
    id: string;
}

export interface AccountPreference {
    id: string;
    user: string;
    stripLog: Record<string, {
            activities: Record<string, {
                    project: string;
                    activity: string;
                    tableConfig: Record<string, IObject>;
                    sampleResultsConfig: Record<string, IObject>;
                }>;
        }>;
    recentProjects?: Record<string, string[]>;
    pinnedProjects?: Record<string, boolean>;
    imagoAccount?: { uid: string; apiToken: string };
}

export interface JsonUser {
    user: User;
    subscriptions: Subscription[];
    selectedSubscriptionId: string;
}

export interface User {
    id: string;
    email: string;
    profile: Profile;
    appcueID: string | null;
    createdAt?: MXDTimestamp | null;
    xid: string;
    uid: string;
}

export interface Profile {
    name: string;
    title?: string;
    initials: string;
    color: string;
}

export interface RecentProject {
    id: string;
    name: string;
}

export interface CurrentSubscription extends AsyncState {
    id: string;
}
